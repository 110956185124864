import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import Login from './components/Login/Login';
import Messenger from 'src/content/applications/Messenger';

// import Attendance from './content/applications/Contractors/contractorDetailTabs/Attendance';
// import ConstructorDetail from './content/applications/Contractors/ConstructorDetail';
const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages

const Overview = Loader(lazy(() => import('src/content/overview')));

// Dashboards

const Crypto = Loader(lazy(() => import('src/content/dashboards/Crypto')));

// Applications

// const Messenger = Loader(
//   lazy(() => import('src/content/applications/Messenger'))
// );
const Positions = Loader(
  lazy(() => import('src/content/applications/positions'))
);

const Announcement = Loader(
  lazy(() => import('src/content/applications/announcement'))
);

const Quote = Loader(lazy(() => import('src/content/applications/quote')));
const CreateQuote = Loader(
  lazy(() => import('src/content/applications/quote/createQuote/index'))
);

const UpdateQuote = Loader(
  lazy(() => import('src/content/applications/quote/updateQuote/updateQuote'))
);

const QuoteVersions = Loader(
  lazy(() => import('src/content/applications/quote/quoteVersions/quoteData'))
);
const UpdateInvoice = Loader(
  lazy(
    () =>
      import(
        'src/content/applications/quote/Invoice/updateInvoice/updateInvoice'
      )
  )
);
const Finanicals = Loader(
  lazy(() => import('src/content/applications/financials'))
);

const QuoteTemplate = Loader(
  lazy(() => import('src/content/applications/quote/QuoteTemplate'))
);

const QuoteTemplateSendToClient = Loader(
  lazy(() => import('src/content/applications/quote/quoteTemplateSendToClient'))
);

const InvoiceTemplate = Loader(
  lazy(() => import('src/content/applications/quote/InvoiceTemplate'))
);

const InvoiceTemplateSendToClient = Loader(
  lazy(
    () => import('src/content/applications/quote/invoiceTemplateSendToClient')
  )
);

const Jobs = Loader(
  lazy(() => import('src/content/applications/Jobs/jobsTabs/availabeJobs'))
);
const AvailableJobsPosition = Loader(
  lazy(
    () =>
      import('src/content/applications/Jobs/jobsTabs/availabeJobs/jobPositions')
  )
);
const CancelledEvents = Loader(
  lazy(
    () =>
      import(
        'src/content/applications/Jobs/jobsTabs/availabeJobs/cancelledEvents'
      )
  )
);
const AvailableJobsPositionBids = Loader(
  lazy(
    () =>
      import(
        'src/content/applications/Jobs/jobsTabs/availabeJobs/jobPositions/jobPositionDetail/jobpositionsBids'
      )
  )
);

const BidsOfPosition = Loader(
  lazy(
    () =>
      import(
        'src/content/applications/Jobs/jobsTabs/availabeJobs/jobPositions/allBids/bidsOfPosition'
      )
  )
);
// const ActiveJobs = Loader(
//   lazy(() => import('src/content/applications/Jobs/jobsTabs/activeJobs'))
// );
// const ActiveJobsPosition = Loader(
//   lazy(() => import('src/content/applications/Jobs/jobsTabs/activeJobs/activeJobsTabs'))
// );

// const ActiveJobsPositionAttrndance = Loader(
//   lazy(() => import('src/content/applications/Jobs/jobsTabs/activeJobs/activeJobsTabs/activejobDetail/contractorAttendanceDetail'))
// );

// const ActiveJobsPositionDetail = Loader(
//   lazy(() => import('src/content/applications/Jobs/jobsTabs/activeJobs/activeJobsTabs/activejobDetail/activeJobsPositionsDetail'))
// );
// const CompleteJobs = Loader(
//   lazy(() => import('src/content/applications/Jobs/jobsTabs/completedJobs'))
// );

// const CompleteJobsDetail = Loader(
//   lazy(() => import('src/content/applications/Jobs/jobsTabs/completedJobs/completedJobsDetailTabs'))
// );

// const CompleteJobsPositionDetail = Loader(
//   lazy(() => import('src/content/applications/Jobs/jobsTabs/completedJobs/completedJobsDetailTabs/completedJobsDetail/completedJobsPositionDetail'))
// );

// const CompleteJobsPositionAttendance = Loader(
//   lazy(() => import('src/content/applications/Jobs/jobsTabs/completedJobs/completedJobsDetailTabs/completedJobsDetail/completedJobsPositionDetail/completedJobscontractorAttendance'))
// );

// const CreateJob = Loader(
//   lazy(() => import('src/content/applications/Jobs/jobsTabs/createJobs'))
// );

const Contractors = Loader(
  lazy(() => import('src/content/applications/Contractors/contractors'))
);

const ContractorsDetails = Loader(
  lazy(() => import('src/content/applications/Contractors/ConstructorDetail'))
);
const Attendance = Loader(
  lazy(
    () =>
      import(
        'src/content/applications/Contractors/contractorDetailTabs/AttendanceDetail'
      )
  )
);

// const ContractorsDetail = Loader(
//   lazy(() => import('src/content/applications/Contractors/ConstructorDetail'))
// );

// const AttendenceDetailOfJob = Loader(
//   lazy(() => import('src/content/applications/Contractors/contractorDetailTabs/jobs/attendenceOfJob'))
// );

const UserProfile = Loader(
  lazy(() => import('src/content/applications/Users/profile'))
);

const UserSettings = Loader(
  lazy(() => import('src/content/applications/Users/settings'))
);

// Components

const Buttons = Loader(
  lazy(() => import('src/content/pages/Components/Buttons'))
);
const Modals = Loader(
  lazy(() => import('src/content/pages/Components/Modals'))
);
const Accordions = Loader(
  lazy(() => import('src/content/pages/Components/Accordions'))
);
const Tabs = Loader(lazy(() => import('src/content/pages/Components/Tabs')));

const Badges = Loader(
  lazy(() => import('src/content/pages/Components/Badges'))
);
const Tooltips = Loader(
  lazy(() => import('src/content/pages/Components/Tooltips'))
);
const Avatars = Loader(
  lazy(() => import('src/content/pages/Components/Avatars'))
);
const Cards = Loader(lazy(() => import('src/content/pages/Components/Cards')));
const Forms = Loader(lazy(() => import('src/content/pages/Components/Forms')));

// Status

const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);

const checkPLS = () => {
  const user = localStorage.getItem('PLS');
  return user ? <Navigate to="/dashboards/home" replace /> : <Login />;
};
const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: checkPLS()
      },
      {
        path: 'quote/quoteTemplate',
        element: <QuoteTemplate />
      },
      {
        path: 'quoteForClient/:id',
        element: <QuoteTemplateSendToClient />
      },
      {
        path: 'quote/invoiceTemplate',
        element: <InvoiceTemplate />
      },
      {
        path: 'invoiceForClient/:id',
        element: <InvoiceTemplateSendToClient />
      },
      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          }
        ]
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },

  {
    path: 'dashboards',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="home" replace />
      },
      {
        path: 'home',
        element: <Crypto />
      },
      {
        path: 'contractors',
        element: <Contractors />
      },
      {
        path: 'contractorsDetails',
        element: <ContractorsDetails />
      },
      {
        path: 'contractors/AttendanceDetail',
        element: <Attendance />
      },
      // {
      //   path: 'contractors/detail/jobs/attendence',
      //   element: <AttendenceDetailOfJob />,

      // },

      {
        path: 'jobTracker',
        element: <Jobs />
      },
      {
        path: 'jobTracker/positions/:id',
        element: <AvailableJobsPosition />
      },
      {
        path: 'jobTracker/positions/bids',
        element: <BidsOfPosition />
      },
      {
        path: 'jobTracker/positions/bids',
        element: <AvailableJobsPositionBids />
      },
      {
        path: 'jobTracker/cancelledEvents',
        element: <CancelledEvents />
      },
      // {
      //   path: 'activeJobs',
      //   element: <ActiveJobs />
      // },
      // {
      //   path: 'activeJobs/positions',
      //   element: <ActiveJobsPosition />
      // },
      // {
      //   path: 'activeJobs/positions/detail',
      //   element: <ActiveJobsPositionDetail />
      // },
      // {
      //   path: 'activeJobs/positions/attendance',
      //   element: <ActiveJobsPositionAttrndance />
      // },
      // {
      //   path: 'completeJobs',
      //   element: <CompleteJobs />
      // },
      // {
      //   path: 'completeJobs/detail',
      //   element: <CompleteJobsDetail />
      // },
      // {
      //   path: 'completeJobs/detail/positions/detail',
      //   element: <CompleteJobsPositionDetail />
      // },
      // {
      //   path: 'completeJobs/detail/positions/attendance',
      //   element: <CompleteJobsPositionAttendance />
      // },
      // {
      //   path: 'jobTracker/createJob',
      //   element: <CreateJob />
      // },

      {
        path: 'messenger',
        element: <Messenger />
      },
      {
        path: 'positions',
        element: <Positions />
      },
      {
        path: 'announcement',
        element: <Announcement />
      },
      {
        path: 'quote/createQuote',
        element: <CreateQuote />
      },
      {
        path: 'quote/updateQuote',
        element: <UpdateQuote />
      },
      {
        path: 'quote/versions',
        element: <QuoteVersions />
      },
      {
        path: 'quote/updateInvoice',
        element: <UpdateInvoice />
      },
      {
        path: 'quote',
        element: <Quote />
      },
      {
        path: 'financials',
        element: <Finanicals />
      }
      // {
      //   path: 'quote/quoteTemplte',
      //   element: <QuoteTemplate />
      // }
    ]
  },
  {
    path: 'management',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="transactions" replace />
      },
      // {
      //   path: 'transactions',
      //   element: <Transactions />
      // },
      {
        path: 'profile',
        children: [
          {
            path: '',
            element: <Navigate to="details" replace />
          },
          {
            path: 'details',
            element: <UserProfile />
          },
          {
            path: 'settings',
            element: <UserSettings />
          }
        ]
      }
    ]
  },
  {
    path: '/components',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="buttons" replace />
      },
      {
        path: 'buttons',
        element: <Buttons />
      },
      {
        path: 'modals',
        element: <Modals />
      },
      {
        path: 'accordions',
        element: <Accordions />
      },
      {
        path: 'tabs',
        element: <Tabs />
      },
      {
        path: 'badges',
        element: <Badges />
      },
      {
        path: 'tooltips',
        element: <Tooltips />
      },
      {
        path: 'avatars',
        element: <Avatars />
      },
      {
        path: 'cards',
        element: <Cards />
      },
      {
        path: 'forms',
        element: <Forms />
      }
    ]
  }
];

export default routes;
