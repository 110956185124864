import { APIClient } from './api_helper';
import * as url from './url_helpter';

const api = new APIClient();

// Login Method
export const postJwtLogin = (data, headers) =>
  api.create(url.POST_LOGIN, data, headers);

export const uploadFile = (data, headers) =>
  api.create(url.UPLOAD_FILE, data, headers);

// User
export const getAllUsers = () => api.get(url.GET_USERS, {});
export const searchUsers = (searchTerm) =>
  api.get(`${url.SEARCH_USERS}/${searchTerm}`, {});
export const getUserJobHistory = (userId) =>
  api.get(`${url.GET_USER_JOB_HISTORY}/${userId}`, {});

// Quote
export const createQuote = (data, headers) =>
  api.create(url.CREATE_QUOTE, data, headers);
export const getAllQuotes = () => api.get(url.GET_QUOTES, {});
export const getAllQuotesHistory = (id) =>
  api.get(`${url.GET_QUOTES_HISTORY}/${id}`, {});
export const getQuoteById = (id) => api.get(`${url.GET_QUOTE_BY_ID}/${id}`, {});
export const changeQuoteStatus = (data, id) =>
  api.update(`${url.CHANGE_QUOTE_STATUS}/${id}`, data);
export const updateQuote = (id, data) =>
  api.update(`${url.UPDATE_QUOTE}/${id}`, data);
export const updateEventNotes = (data, id) =>
  api.update(`${url.UPDATE_EVENT_NOTES}/${id}`, data);
export const deleteQuote = (id) => api.delete(`${url.DELETE_QUOTE}/${id}`, {});

export const changeUserPassword = (data, headers) =>
  api.create(url.CHANGE_PASSWORD, data, headers);

export const getAllEvents = (params) => api.get(url.GET_EVENTS, params);

export const getAllShifts = (id) => api.get(`${url.GET_SHIFTS}/${id}`, {});
export const assignShiftRequest = (data, headers) =>
  api.create(url.ASSIGN_SHIFTS_REQUEST, data, headers);
export const assignShift = (data, headers) =>
  api.create(url.ASSIGN_SHIFTS, data, headers);
export const deleteShift = (id) => api.delete(`${url.DELETE_SHIFT}/${id}`, {});
export const createLead = (data, id) =>
  api.update(`${url.CREATE_LEAD}/${id}`, data);

export const getAllBids = (id) => api.get(`${url.GET_BIDS}/${id}`, {});
export const deleteBid = (id) => api.delete(`${url.DELETE_BID}/${id}`, {});

export const getAllJobs = (id) => api.get(`${url.GET_JOBS}/${id}`, {});
export const updateJob = (data, id) =>
  api.update(`${url.UPDATE_JOB}/${id}`, data);
export const updateJobNotes = (data, id) =>
  api.update(`${url.UPDATE_JOB_NOTES}/${id}`, data);
export const deleteJob = (id) => api.delete(`${url.DELETE_JOB}/${id}`, {});

export const createInvoice = (data, headers) =>
  api.create(url.CREATE_INVOICE, data, headers);
export const approveInvoice = (data, headers) =>
  api.create(url.APPROVE_INVOICE, data, headers);

export const getClientInvoiceJobs = (id) =>
  api.get(`${url.CLINET_INVOICE_JOBS}/${id}`, {});
export const createClientInvoiceJobs = (data, headers) =>
  api.create(url.CREATE_INVOICE_JOBS, data, headers);
export const changeClientInvoiceStatus = (data, id, headers) =>
  api.create(`${url.CHANGE_INVOICE_JOBS}/${id}`, data, headers);

export const getAllChats = (params) => api.get(url.GET_ALL_CHATS, params);
export const findChat = (id) => api.get(`${url.FIND_CHAT}/${id}`, {});
export const getMessages = (id, params) =>
  api.get(`${url.GET_MESSAGES}/${id}`, params);
export const sendMessage = (data, headers) =>
  api.create(url.CREATE_MESSAGE, data, headers);

export const getAllAttedance = (id) =>
  api.get(`${url.GET_ATTENDANCE}/${id}`, {});

export const sendAnnouncement = (data, headers) =>
  api.create(url.SEND_NOTIFICATION, data, headers);

export const sendAnnouncement2 = (data, headers) =>
  api.create(url.SEND_NOTIFICATION2, data, headers);

export const getCalender = ({ month, year }) =>
  api.get(url.GET_CALENDER, { month, year });

export const sendReminders = (data, headers) =>
  api.create(url.SEND_REMINDERS, data, headers);
